@import './styles/abstracts/variables.scss';
@import './styles/abstracts/Clear.scss';

@import './styles/layout/Layout.scss';
@import './styles/base/Icons.scss';
@import './styles/base/base.scss';

@import './styles/components/Select.scss';
@import './styles/components/Collapsable.scss';

@import './styles/components/steps/CutList.scss';
@import './styles/pages/Configurator.scss';

@import './styles/pages/Config.scss';
@import './styles/pages/Document.scss';

// =======================================
// =======================================
* {
	box-sizing: border-box;
}

.App {
	text-align: center;
	font-size: 12px;
	@media (min-width: $breakpoint-tablet) {
		font-size: 16px;
	}

	input {
		font-size: 11px;

		@media (min-width: $breakpoint-tablet) {
			font-size: 13px;
		}

		&.element-title {
			font-size: 32px;

			&.small-title {
				font-size: 24px;
			}

			&.title-mini {
				font-size: 20px;
			}

			@media (min-width: $breakpoint-tablet) {
				font-size: 40px;

				&.small-title {
					font-size: 32px;
				}

				&.title-mini {
					font-size: 24px;
				}
			}
		}

		&.element-subtitle {
			font-size: 175%;
		}
	}
}

.clickable {
	cursor: pointer;
}

.center-it-div {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dropdown-menu {
	position: relative;

	width: 100%;
	height: 100%;
	.dropdown-menu-label {
		display: flex;
		justify-content: center;
		align-items: center;
		user-select: none;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}
	.dropdown-menu-content {
		// position: absolute;
		z-index: 500;
		// visibility: hidden;
		// transition: visibility 0ms 200ms ease-in-out;

		// animation-name: hide-opacity;
		// animation-duration: 200ms;
		// animation-fill-mode: both;
		// animation-timing-function: ease-in-out;

		// &.show {
		// 	animation-name: show-opacity;

		// 	transition: visibility 0ms 0ms ease-in-out;
		// 	visibility: visible;
		// }
	}
}

.shadow {
	box-shadow: 0 5px 20px $shadow-color;
}

.shadow-close {
	box-shadow: 0 3px 10px rgba(87, 87, 87, 0.2);

	&.shadow-small {
		box-shadow: 0 2px 6px rgba(87, 87, 87, 0.2);
	}
}

.element-title {
	font-size: 32px;
	font-weight: 500;
	text-align: left;
	outline: none;
	border: none;
	background: $background-color;
	// margin-bottom: 5px;

	&.small-title {
		font-size: 24px;
		color: $color-disable;
		margin: 0.5em 0.2em;
	}

	&.title-mini {
		font-size: 20px;
	}

	&.title-space {
		margin-bottom: 5px;
	}

	@media (min-width: $breakpoint-tablet) {
		font-size: 40px;

		&.small-title {
			font-size: 32px;
			color: $color-disable;
			margin: 0.5em 0.2em;
		}

		&.title-mini {
			font-size: 24px;
		}
	}
}

.element-subtitle {
	font-size: 175%;
	font-weight: 500;
	text-align: left;
	outline: none;
	border: none;
	background: $background-color;
}

.element-description {
	font-size: 100%;
	font-weight: 400;
	text-align: left;
	outline: none;
	border: none;
	padding: 1rem;
	// margin: 2rem 0;
	border-radius: 0.5rem;
	background: $background-color;

	&[placeholder]:empty:before {
		content: attr(placeholder);
		color: #777;
		cursor: text;
	}
}

.element-draw {
	font-size: 100%;
	font-weight: 400;
	text-align: left;
	outline: none;
	border: none;
	padding: 1rem;
	// margin: 2rem 0;
	border-radius: 0.5rem;
	background: $background-color;

	&[placeholder]:empty:before {
		content: attr(placeholder);
		color: #777;
		cursor: text;
	}
}

.element-separator {
	outline: none;
	border: none;
	height: 8px;
	border-radius: 8px;
	background-color: $border-color;
	// border: #282c34;
	// border-width: 2px;
}

.element-full {
	width: 100%;
}

.input-file-hidden {
	position: absolute;
	width: 0.1px;
	height: 0.1px;
	overflow: hidden;
	// z-index: -9999;
	visibility: hidden;
	opacity: 0;
}
