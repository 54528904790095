@keyframes growAndFade {
	0% {
		opacity: 0.5;
		transform: scale(0.25);
	}
	100% {
		opacity: 0;
		transform: scale(1);
	}
}

@keyframes step-option-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@mixin animation-mixin($name, $from, $to) {
	@keyframes #{$name} {
		0% {
			height: $from;
		}
		100% {
			height: $to;
		}
	}
}

@keyframes hide-option {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		visibility: visible;
	}
}

@keyframes show-opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes hide-opacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes icon-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes open-option-drawer {
	0% {
		transform-origin: right;
		transform: scaleX(0) scaleY(0);
		margin-right: 15px;
	}
	10% {
		transform-origin: right;
		transform: scaleX(0.1) scaleY(0.5);
		margin-right: 13px;
	}
	100% {
		transform-origin: right;
		transform: scaleX(1) scaleY(1);
		margin-right: 0px;
	}
}

@keyframes close-option-drawer {
	0% {
		transform-origin: right;
		transform: scaleX(1) scaleY(1);
		margin-right: 0px;
	}
	20% {
		transform-origin: right;
		transform: scaleX(1) scaleY(1);
		margin-right: 0px;
	}

	95% {
		transform-origin: right;
		transform: scaleX(0.1) scaleY(0.5);
		margin-right: 13px;
	}
	100% {
		transform-origin: right;
		transform: scaleX(0) scaleY(0);
		margin-right: 15px;
	}
}

@keyframes flip-in-hor-bottom {
	0% {
		-webkit-transform: rotateX(80deg);
		transform: rotateX(80deg);
		opacity: 0;
	}
	100% {
		-webkit-transform: rotateX(0);
		transform: rotateX(0);
		opacity: 1;
	}
}

@keyframes Toastify__flipIn {
	from {
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		animation-timing-function: ease-in;
		opacity: 0;
	}
	40% {
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		animation-timing-function: ease-in;
	}
	60% {
		transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
		opacity: 1;
	}
	80% {
		transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	}
	to {
		transform: perspective(400px);
	}
}

@keyframes Toastify__flipOut {
	from {
		transform: perspective(400px);
	}
	30% {
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		opacity: 1;
	}
	to {
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		opacity: 0;
	}
}

@keyframes Toastify__trackProgress {
	0% {
		transform: scaleX(1);
	}
	100% {
		transform: scaleX(0);
	}
}
