@import '../abstracts/variables.scss';

.shipping-title {
	text-align: left;
	// background: $background-color;
}

.shipping-content {
	position: relative;
	padding: 1rem 1.5rem;
	border-radius: 0.5rem;
	background: $background-color;

	.shipping-data-row {
		display: flex;
		align-items: center;
		justify-content: center;

		@media only screen and (max-width: $breakpoint-laptop) {
			flex-wrap: wrap;
		}

		.shipping-entry {
			flex-grow: 0;
			flex-shrink: 0;
			margin: 0 0.4rem;
			text-align: left;

			@media only screen and (max-width: $breakpoint-laptop) {
				margin: 0.4rem;
				position: relative;
			}

			.shipping-entry-label {
				// width: 100%;
				text-align: left;
				font-weight: 600;
				padding-right: 0.5rem;

				word-wrap: break-word;
				@media (min-width: $breakpoint-laptop) {
					// display: none;
				}
			}

			.shipping-entry-input {
				position: relative;
				padding: 0.2rem;
				text-align: left;
				outline: none;
				border: none;
				border-radius: 2px;
				width: 100%;
				height: 30px;
				resize: none;
				box-shadow: 0 0 0 1px #444444;

				&:focus {
					box-shadow: 0 0 0 2px #444444;
				}
			}

			.shipping-entry-notes {
				font-size: 13.3px;
				height: auto;
				overflow: hidden;
				min-height: 30px;
				padding: 8px;
			}

			.shipping-search {
				position: absolute;
				top: 100%;
				left: 0;
				padding: 0.25rem;
				border-radius: 0.5rem;
				border-style: solid;
				border-width: 2px;
				border-color: $color-blue;
				width: calc(100% - 0.25rem);
				// Min === use css min, min === use SASS min
				max-height: Min(24rem, 75vh);
				overflow: auto;
				background: $background-color;
				margin: 0.125rem 0.25rem;
				z-index: 750;

				.shipping-search-item {
					padding: 0.25rem;
					margin-top: 0.25rem;
					cursor: pointer;
					width: 100%;
					display: flex;
					border-radius: 0.25rem;

					&:hover,
					&:focus {
						outline: none;
						background: rgb(37, 204, 255);
					}
				}
			}
		}

		.shipping-name {
			width: calc(30% - 0.8rem);

			@media only screen and (max-width: $breakpoint-laptop) {
				width: calc(100% - 0.8rem);
			}
		}

		.shipping-price {
			width: calc(15% - 0.8rem);

			@media only screen and (max-width: $breakpoint-laptop) {
				width: calc(100% - 0.8rem);
			}
		}

		.shipping-notes {
			width: calc(55% - 0.8rem);

			@media only screen and (max-width: $breakpoint-laptop) {
				width: calc(100% - 0.8rem);
			}
		}
	}
}
