@import '../abstracts/variables.scss';
@import '../abstracts/Animations.scss';
// @import '../App.scss';

$form-shadow: #000;

.form-page {
	// margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgb(42, 23, 44);
	// min-height: 100vh;

	.form-form {
		// width: 500px;
		position: relative;
		overflow: auto;
		max-width: 80vw;
		max-height: 80vh;
		display: flex;
		flex-wrap: wrap;
		padding: 2rem;
		border-radius: 2rem;
		box-shadow: 0px 6px $form-shadow, 0 5px 35px $form-shadow, 0 5px 15px $form-shadow, 0 0px 10px $form-shadow;
		background-color: $background-color;

		.form-actions {
			position: absolute;
			top: 1rem;
			right: 1.5rem;
		}

		.form-entry {
			width: 350px;
			// margin: auto;
			padding: 1rem;

			.apps-list {
				.app-entry {
					margin: 10px 0;
					display: grid;
					align-items: center;
					grid-template-columns: 1fr 1fr 1fr;

					& > * {
						padding: 0 10px;
					}

					.remove-app {
						outline: none;
						border: none;
						background-color: rgb(13, 13, 14);
						color: #fff;
						width: 100%;

						padding: 0.5rem;
						border-radius: 1rem;
						color: #fff;
						cursor: pointer;

						font-size: 105%;
						font-weight: 500;
					}
				}
			}

			.new-app {
				display: grid;
				align-items: center;
				grid-template-columns: 1fr 1fr 1fr;

				& > * {
					padding: 0 10px;
				}

				.add-app {
					outline: none;
					border: none;
					background-color: rgb(13, 13, 14);
					color: #fff;
					width: 100%;

					padding: 0.5rem;
					border-radius: 1rem;
					color: #fff;
					cursor: pointer;

					font-size: 105%;
					font-weight: 500;
				}
			}

			.form-array {
				display: flex;
				flex-wrap: wrap;

				.form-array-item {
					user-select: none;
					padding: 5px;
					cursor: pointer;
					border-width: 1px;
					border-style: solid;
					// border-radius: 5px;
				}
			}

			.form-list {
				.form-list-item {
					position: relative;
					padding: 6px 3px;
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					align-items: center;

					&:not(&:last-child) {
						border-style: dashed;
						border-width: 0;
						border-bottom-width: 1px;
					}

					&:hover {
						.list-item-preview-action {
							animation-name: show-opacity;
							// opacity: 1;
						}
					}

					.list-item-remove {
						position: absolute;
						right: 0%;
						top: 50%;

						.list-item-remove-icon {
							position: relative;
							bottom: 50%;
							left: 50%;
						}
					}

					.list-item-preview-action {
						width: 30px;
						height: 30px;
						padding: 5px;
						transform: none;
						opacity: 0;

						animation-name: show-opacity;

						@media (min-width: $breakpoint-laptop) {
							animation-name: hide-opacity;
						}
						animation-duration: 400ms;
						animation-fill-mode: both;
						animation-timing-function: ease-in-out;
					}
				}
			}

			.form-label {
				padding-bottom: 0.2rem;
				display: flex;
				align-items: flex-end;
				width: 100%;
				.label {
					margin-left: 5px;
				}
			}

			.form-input {
				padding: 0.5rem;
				width: 100%;
				outline: none;
				border: none;

				background-color: rgba(0, 0, 0, 0.15);
			}
		}
		.form-submit {
			outline: none;
			border: none;
			background-color: rgb(13, 13, 14);
			color: #fff;
			width: 100%;
			margin: 0 auto;

			margin-top: 1rem;
			padding: 0.5rem;
			border-radius: 1rem;
			color: #fff;
			cursor: pointer;

			font-size: 105%;
			font-weight: 500;
		}
		// margin: auto;
	}
}
