@import '../abstracts/variables.scss';
@import '../abstracts/Animations.scss';

.setting-panel {
	background-color: $background-color;
	position: relative;
	padding: 0.5rem;
	border-style: solid;
	border-radius: 0.5rem;
	border-width: 3px;
	// min-width: 200px;
	// width: 50%;
	width: 300px;
	z-index: 750;

	color: #000;
	fill: #000;

	.setting-section {
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		.setting-section-title {
			width: 100%;
			font-size: larger;
			font-weight: 600;
			margin: 1rem 0;
		}

		.setting-option {
			margin: auto;
			padding: 1rem;
			border-radius: 0.35rem;
			width: 100%;
			min-width: 70px;
			max-width: 80vw;
			hyphens: auto;
			word-wrap: break-word;
			background-color: $background-color;

			transition: background-color 250ms 0ms ease-in-out, fill 150ms 0ms ease-in-out, color 150ms 0ms ease-in-out;

			&:hover {
				// @extend .shadow;
				&.hoverable {
					box-shadow: 0em calc(1 * 0 * 0.2rem) calc(1 * 0.5rem) $shadow-color, 0em calc(1 * 0 * 0.4rem) calc(1 * 1rem) $shadow-color,
						0em calc(1 * 0 * 0.5rem) calc(1 * 1.5rem) $shadow-color;

					background-color: rgb(55, 141, 253);
					fill: #fff;
					color: #fff;
				}
			}

			&.active {
				box-shadow: 0em calc(1 * 0 * 0.2rem) calc(1 * 0.5rem) $shadow-color, 0em calc(1 * 0 * 0.4rem) calc(1 * 1rem) $shadow-color,
					0em calc(1 * 0 * 0.5rem) calc(1 * 1.5rem) $shadow-color;

				background-color: rgb(55, 141, 253);
				fill: #fff;
				color: #fff;
			}

			&.option-1\/2 {
				width: calc(50%);
				// width: calc(100%);
				// @media (min-width: $breakpoint-laptop) {
				// 	width: calc(50%);
				// }
			}
			&.option-1\/3 {
				width: calc(33%);
				// width: calc(100%);
				// @media (min-width: $breakpoint-laptop) {
				// 	width: calc(33%);
				// }
			}
		}
	}
}
