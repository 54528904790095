.collapsable {
	&.open {
		.collapsable-toggle {
			svg {
				transform: rotate(180deg);
			}
		}
		.collapsable-content {
			transition: opacity 250ms 350ms ease-in-out, max-height 450ms ease-in-out;
			opacity: 1;
		}
	}

	.collapsable-toggle {
		margin: 0.25rem;
		padding: 0.5rem;
		border-radius: 0.25rem;
		width: calc(100% - 0.5rem);
		min-width: 5rem;
		cursor: pointer;
		user-select: none;
		hyphens: auto;
		word-wrap: break-word;
		background-color: $background-color;

		transition: background-color 250ms 0ms ease-in-out, fill 150ms 0ms ease-in-out, color 150ms 0ms ease-in-out;

		@media (min-width: $breakpoint-tablet) {
			margin: 0.5rem;
			padding: 1rem;
			border-radius: 0.35rem;
			width: calc(100% - 1rem);
		}

		&:hover {
			// @extend .shadow;
			box-shadow: 0em calc(1 * 0 * 0.2rem) calc(1 * 0.5rem) $shadow-color, 0em calc(1 * 0 * 0.4rem) calc(1 * 1rem) $shadow-color,
				0em calc(1 * 0 * 0.5rem) calc(1 * 1.5rem) $shadow-color;

			background-color: rgb(55, 141, 253);
			fill: #fff;
			color: #fff;
		}

		svg {
			transition: transform 250ms 0ms ease-in-out;
		}

		// &.active {
		// 	box-shadow: 0em calc(1 * 0 * 0.2rem) calc(1 * 0.5rem) $shadow-color, 0em calc(1 * 0 * 0.4rem) calc(1 * 1rem) $shadow-color,
		// 		0em calc(1 * 0 * 0.5rem) calc(1 * 1.5rem) $shadow-color;

		// 	background-color: rgb(55, 141, 253);
		// 	fill: #fff;
		// 	color: #fff;
		// }
	}

	.collapsable-content {
		// max-height: 0px;
		opacity: 0;
		overflow: hidden;

		transition: opacity 250ms ease-in-out, max-height 450ms 200ms ease-in-out;
	}
}
