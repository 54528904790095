@import '../abstracts/variables.scss';
// @import '../App.scss';

$login-shadow: #000;

.login-page {
	// margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgb(66, 66, 66);
	min-height: 100vh;

	.login-form {
		display: flex;
		flex-wrap: wrap;
		width: 500px;
		padding: 2rem;
		border-radius: 0.25rem;
		box-shadow: 0px 6px $login-shadow, 0 5px 35px $login-shadow, 0 5px 15px $login-shadow, 0 0px 10px $login-shadow;
		background-color: $background-color;

		// margin: auto;
		.login-form-label {
			padding-bottom: 0.2rem;
			display: flex;
			align-items: flex-end;

			.label {
				margin-left: 5px;
			}
		}

		.login-form-input {
			padding: 0.5rem;
			margin-bottom: 1rem;
			width: 100%;
			outline: none;
			border: none;

			background-color: rgba(0, 0, 0, 0.15);
		}

		.login-form-error-label {
			color: rgb(190, 4, 4);
		}

		.login-form-submit {
			outline: none;
			border: none;
			background-color: rgb(13, 13, 14);
			color: #fff;
			width: 100%;
			margin: 0 auto;

			margin-top: 1rem;
			padding: 0.5rem;
			border-radius: 1rem;
			color: #fff;
			cursor: pointer;

			font-size: 105%;
			font-weight: 500;
		}
	}
}
