@import '../../abstracts/variables.scss';

.cut-list-grid {
	// display: flex;
	display: grid;
	grid-template-columns: 1fr 2fr 2fr 6fr;
	// gap: 5px;
	width: 100%;

	&.compact {
		grid-template-columns: 1fr 2fr 2fr 2fr;

		.cut-list-drawing-area {
			grid-template-columns: 1fr;
		}
	}

	.cut-list-cell {
		padding: 4px;
		border-left: $border-color 2px solid;

		&:nth-child(4n) {
			border-right: $border-color 2px solid;
		}
	}

	.cut-list-drawing-area {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 5px;
		// width: 100%;
	}
}
