@import '../abstracts/variables.scss';

.vertical-input {
	.input-label {
		margin: 5px;
	}

	.input-button {
		margin: 0.5rem;
		padding: 1rem;
		border-radius: 0.35rem;
		width: calc(100% - 1rem);
		min-width: 5rem;
		outline: none;
		border: none;
		cursor: pointer;
		user-select: none;
		hyphens: auto;
		word-wrap: break-word;
		background-color: $background-color;

		transition: background-color 250ms 0ms ease-in-out, fill 150ms 0ms ease-in-out, color 150ms 0ms ease-in-out;

		&.disable-hover {
			cursor: default;
		}

		&:not(.disable-hover):hover {
			// @extend .shadow;
			box-shadow: 0em calc(1 * 0 * 0.2rem) calc(1 * 0.5rem) $shadow-color, 0em calc(1 * 0 * 0.4rem) calc(1 * 1rem) $shadow-color,
				0em calc(1 * 0 * 0.5rem) calc(1 * 1.5rem) $shadow-color;

			background-color: rgb(55, 141, 253);
			fill: #fff;
			color: #fff;
		}

		&.active {
			box-shadow: 0em calc(1 * 0 * 0.2rem) calc(1 * 0.5rem) $shadow-color, 0em calc(1 * 0 * 0.4rem) calc(1 * 1rem) $shadow-color,
				0em calc(1 * 0 * 0.5rem) calc(1 * 1.5rem) $shadow-color;

			background-color: rgb(55, 141, 253);
			fill: #fff;
			color: #fff;
		}
	}

	input,
	.area-input {
		// font-size: 250%;
		margin: 5px;
		padding: 5px;
		font-weight: 500;
		text-align: left;
		outline: none;
		border: none;
		border-bottom: solid 2px $border-color;
		background: $background-color;
	}
}

.sidebar-button-mobile {
	top: 0px;
	left: 0px;
	// width: 100vw;
	height: 40px;
	position: fixed;
	z-index: 1000;
	background-color: $background-color;
	// border: solid 2px black;
	border: none;
	padding: 5px;
	text-align: left;

	svg {
		width: 30px;
		height: 30px;
	}

	&.open {
		svg {
			transform: rotateY(180deg);
		}
	}

	@media (min-width: $breakpoint-tablet) {
		display: none;
	}
}

.sidebar {
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 750;
	background-color: $background-color;
	padding-top: 30px;

	overflow: scroll;

	&::-webkit-scrollbar {
		display: none;
		width: 0;
		height: 0;
	}

	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: auto;
	align-self: auto;

	border: $border-color;
	border-width: 0 0 0 2px;
	border-style: solid;

	// max-height: calc(100vh - 60px);

	transition: transform ease-out 300ms;
	transform: translate(-100%, 0);
	&.open {
		transform: translate(0);
	}

	@media (min-width: $breakpoint-tablet) {
		padding-left: 1rem;
		padding-right: 5px;
		padding-top: 0px;
		position: sticky;
		width: auto;
		height: auto;
		min-height: calc(100vh - 4.5rem);
		max-height: 100vh;
		z-index: 0;

		transform: translate(0);
	}

	.sidebar-section {
		width: 100%;
		margin-top: 1rem;
		display: flex;
		flex-wrap: wrap;

		.sidebar-section-title {
			width: 100%;
			font-size: larger;
			font-weight: 600;
			margin: 0.75rem 0;

			@media (min-width: $breakpoint-tablet) {
				margin: 1rem 0;
			}
		}

		.sidebar-option {
			margin: 0.25rem;
			padding: 0.5rem;
			border-radius: 0.25rem;
			width: calc(50% - 0.5rem);
			min-width: 5rem;
			cursor: pointer;
			user-select: none;
			hyphens: auto;
			word-wrap: break-word;
			background-color: $background-color;

			@media (min-width: $breakpoint-tablet) {
				margin: 0.5rem;
				padding: 1rem;
				border-radius: 0.35rem;
				width: calc(100% - 1rem);
			}

			@media (min-width: $breakpoint-desktop) {
				width: calc(50% - 1rem);
			}
			transition: background-color 250ms 0ms ease-in-out, fill 150ms 0ms ease-in-out, color 150ms 0ms ease-in-out;

			&.disable-hover {
				cursor: default;
			}

			&:not(.disable-hover):hover {
				// @extend .shadow;
				box-shadow: 0em calc(1 * 0 * 0.2rem) calc(1 * 0.5rem) $shadow-color, 0em calc(1 * 0 * 0.4rem) calc(1 * 1rem) $shadow-color,
					0em calc(1 * 0 * 0.5rem) calc(1 * 1.5rem) $shadow-color;

				background-color: rgb(55, 141, 253);
				fill: #fff;
				color: #fff;
			}

			&.active {
				box-shadow: 0em calc(1 * 0 * 0.2rem) calc(1 * 0.5rem) $shadow-color, 0em calc(1 * 0 * 0.4rem) calc(1 * 1rem) $shadow-color,
					0em calc(1 * 0 * 0.5rem) calc(1 * 1.5rem) $shadow-color;

				background-color: rgb(55, 141, 253);
				fill: #fff;
				color: #fff;
			}

			&.option-full {
				width: calc(100% - 1rem);
			}
			&.option-1-3 {
				width: calc(100% - 1rem);
				@media (min-width: $breakpoint-desktop) {
					width: calc(33% - 1rem);
				}
			}
		}
	}
}

.sidebar-nav {
	// position: absolute;

	display: none;
	@media (min-width: $breakpoint-tablet) {
		display: block;
	}

	position: sticky;
	top: 0px;
	right: 0px;
	flex: 0 0 60px;
	padding: 5px;
	padding-top: 10px;
	// padding-right: 10px;
	flex-basis: auto;
	align-self: flex-start;

	.sidebar-link {
		width: 50px;
		height: 50px;
		padding: 5px;
		margin: 5px 0px;
		border-radius: 5px;
		cursor: pointer;
		user-select: none;
		hyphens: auto;
		word-wrap: break-word;
		background-color: $background-color;

		transition: background-color 250ms 0ms ease-in-out, fill 150ms 0ms ease-in-out, color 150ms 0ms ease-in-out;

		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 30px;
			height: 30px;
		}

		&.disable-hover {
			cursor: default;
		}

		&:not(.disable-hover):hover {
			// @extend .shadow;
			box-shadow: 0em calc(1 * 0 * 0.2rem) calc(1 * 0.5rem) $shadow-color, 0em calc(1 * 0 * 0.4rem) calc(1 * 1rem) $shadow-color,
				0em calc(1 * 0 * 0.5rem) calc(1 * 1.5rem) $shadow-color;

			background-color: rgb(55, 141, 253);
			fill: #fff;
			color: #fff;
		}

		&.active {
			box-shadow: 0em calc(1 * 0 * 0.2rem) calc(1 * 0.5rem) $shadow-color, 0em calc(1 * 0 * 0.4rem) calc(1 * 1rem) $shadow-color,
				0em calc(1 * 0 * 0.5rem) calc(1 * 1.5rem) $shadow-color;

			background-color: rgb(55, 141, 253);
			fill: #fff;
			color: #fff;
		}
	}
	// width: 100px;
}

.sidebar-pin {
	// position: absolute;

	display: none;
	@media (min-width: $breakpoint-tablet) {
		display: block;
	}

	position: sticky;
	top: 0px;
	right: 0px;
	flex: 0 0 60px;
	// padding: 5px;
	padding-top: 10px;
	padding-right: 10px;
	flex-basis: auto;
	align-self: flex-start;

	.sidebar-pin {
		width: 32px;
		height: 32px;
		padding: 3px;
		margin: 5px 0px;
		margin-bottom: 23px;
		border-radius: 5px;
		cursor: pointer;
		user-select: none;
		hyphens: auto;
		word-wrap: break-word;
		background-color: $background-color;

		transition: background-color 250ms 0ms ease-in-out, fill 150ms 0ms ease-in-out, color 150ms 0ms ease-in-out;

		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 15px;
			height: 15px;
		}

		&.disable-hover {
			cursor: default;
		}

		&:not(.disable-hover):hover {
			// @extend .shadow;
			box-shadow: 0em calc(1 * 0 * 0.2rem) calc(1 * 0.5rem) $shadow-color, 0em calc(1 * 0 * 0.4rem) calc(1 * 1rem) $shadow-color,
				0em calc(1 * 0 * 0.5rem) calc(1 * 1.5rem) $shadow-color;

			background-color: rgb(55, 141, 253);
			fill: #fff;
			color: #fff;
		}

		&.active {
			box-shadow: 0em calc(1 * 0 * 0.2rem) calc(1 * 0.5rem) $shadow-color, 0em calc(1 * 0 * 0.4rem) calc(1 * 1rem) $shadow-color,
				0em calc(1 * 0 * 0.5rem) calc(1 * 1.5rem) $shadow-color;

			background-color: rgb(55, 141, 253);
			fill: #fff;
			color: #fff;
		}
	}
	// width: 100px;
}
