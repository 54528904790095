@import '../abstracts/variables.scss';

.select-container {
	position: relative;
	.select-input {
		padding: 6px;
		outline: none;
		caret-color: transparent;
		cursor: default;
	}
	.select-options-window {
		position: absolute;
		top: 100%;
		z-index: 500;
		width: 100%;
		background-color: $background-color;
		transform: scaleY(0);
		transform-origin: top;
		transition: transform 250ms 0ms ease-in-out;

		margin-top: 4px;

		border-style: solid;
		border-width: 2px;
		border-color: $color-blue;

		&.open {
			transform: scaleY(1);

			.select-option {
				opacity: 1;
				transition: opacity 175ms 75ms linear;
			}
		}
		.select-option {
			padding: 6px;
			opacity: 0;
			transition: opacity 150ms 0ms linear;
			cursor: pointer;
			user-select: none;
			line-height: 1.2rem;

			border-style: solid;
			border-width: 0px;

			&:not(:first-child) {
				border-top-width: 1px;
			}
			&:not(:last-child) {
				border-bottom-width: 1px;
			}
			border-color: $color-blue;

			&:focus {
				background-color: lighten($color-blue, 30%);
				outline: none;
				border-color: darken($color-blue, 10%);
			}
			&:hover {
				background-color: lighten($color-blue, 30%);
				outline: none;
				border-color: darken($color-blue, 10%);
			}
			.active {
				background-color: lighten($color-blue, 30%);
				outline: none;
				border-color: darken($color-blue, 10%);
			}
		}
	}
}
