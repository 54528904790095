@import '../abstracts/variables.scss';
@import './Footer.scss';
@import './Header.scss';
@import './Sidebar.scss';

.layout {
	max-width: 140rem;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	// overflow: hidden;
	// max-height: 100vh;

	.header {
		// height: 70px;
		// line-height: 30px;
		// border-color: $border-color;
		// border-width: 0 0 2px 0;
		// border-style: solid;
	}
	.container {
		display: flex;
		flex-direction: column-reverse;
		flex-wrap: nowrap;
		justify-content: center;
		align-content: stretch;
		align-items: flex-start;

		@media (min-width: $breakpoint-tablet) {
			flex-direction: row;
		}
		.content {
			width: 100%;
			max-width: 100vw;
			padding-bottom: 30%;

			@media (min-width: $breakpoint-tablet) {
				width: 75%;
				// flex-basis: 75%;
				flex-grow: 1;
			}
		}

		.sidebar {
			width: 100%;
			min-width: 200px;
			max-width: 100vw;

			@media (min-width: $breakpoint-tablet) {
				width: 25%;
				max-width: 450px;
			}
		}
	}
}
