@import '../abstracts/Animations.scss';

.loading-page {
	width: 100vw;
	height: 100vh;
	display: flex;
}

.loading-icon {
	margin: auto;
	height: 35%;
	width: 35%;
	min-height: 200px;
	min-width: 200px;

	svg {
		height: 100%;
		width: 100%;

		animation: App-logo-spin infinite 1250ms linear;
	}
}

.icon-text {
	display: flex;
	flex-direction: column;

	.icon-text-icon {
		margin-bottom: 0.25rem;

		svg {
			width: 2.25rem;
			height: 2.25rem;
		}
		// stroke-width: 200%;
	}
	.icon-text-text {
		font-weight: 500;
	}
}

.icon-hover {
	background: $background-color;
	box-shadow: 0em 0em 0.25em $shadow-sidebar-color;
	border-radius: 100%;
	padding: 0.4rem;
	cursor: pointer;
	user-select: none;
	transition: background-color 250ms 0ms ease-in-out, fill 150ms 0ms ease-in-out, color 150ms 0ms ease-in-out;

	&:hover {
		background-color: #378dfd;
		fill: #fff;
		color: #fff;
	}

	&.active {
		background-color: #378dfd;
		fill: #fff;
		color: #fff;
	}

	&.disabled {
		background-color: #b0b2b4;
		fill: #000;
		color: #000;
		cursor: default;
		cursor: not-allowed;
	}
}
