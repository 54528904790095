@import '../abstracts/variables.scss';
@import '../abstracts/mixins.scss';

.header {
	position: relative;
	padding: 0px 17px;
	box-shadow: 0 5px 10px rgba(87, 87, 117, 0.1);
	// Keep it slight above sidebar
	z-index: 10;

	padding-left: 40px;

	@media (min-width: $breakpoint-tablet) {
		padding-left: 17px;
	}

	.header-menu {
		min-height: 60px;
		line-height: 1.5rem;
		display: flex;
		flex-wrap: wrap;

		// border: $border-color;
		// border-width: 0 0 2px 0;
		// border-style: solid;

		.header-item {
			background-color: $background-color;
			color: #000;
			fill: #000;
			transition: background-color 300ms ease-in-out, color 300ms ease-in-out, fill 300ms ease-in-out, border-color 300ms ease-in-out;

			display: flex;
			justify-content: center;
			align-items: center;

			margin: 0px 5px;
			padding: 3px;

			&.selected {
				// background-color: $color-blue;
				// color: #fff;
				// fill: #fff;

				// .header-link {
				// 	@include underline(#fff, 2px);

				// 	&:not(.not-hoverable):hover {
				// 		@include underline-hover(#fff);
				// 	}

				// 	&.active {
				// 		@include underline-hover(#fff);
				// 	}
				// }
			}

			.header-link {
				cursor: pointer;
				// padding: 2px;
				// background-color: $color-blue;
				// color: #fff;
				// fill: #fff;
				@include underline(black, 2px);

				&:not(.not-hoverable):hover {
					@include underline-hover(black);
				}

				&.active {
					@include underline-hover(black);
				}
			}

			.header-button {
				padding: 5px;
				cursor: pointer;

				@include underline(black, 2px);

				&:not(.not-hoverable):hover {
					@include underline-hover(black);
				}

				&.active {
					@include underline-hover(black);
				}

				// &.active {
				// }
			}
		}

		// .header-link {
		// 	display: flex;
		// 	justify-content: center;
		// 	align-items: center;
		// 	cursor: pointer;

		// 	background-color: $background-color;
		// 	transition: background-color 300ms ease-in-out, color 300ms ease-in-out, fill 300ms ease-in-out, box-shadow 300ms ease-in-out;

		// 	&.active {
		// 		// background-color: $color-blue;
		// 		// color: #fff;
		// 		// fill: #fff;
		// 		// box-shadow: inset 0 -3px 0 darken($color-blue, 10%);
		// 		// border-color: $border-color-active;

		// 		.header-link {
		// 			border-bottom: solid 1px black;
		// 		}
		// 	}
		// 	&:hover {
		// 		background-color: darken($color-blue, 5%);
		// 		color: #fff;
		// 		fill: #fff;
		// 		box-shadow: inset 0 -2px 0 $border-color;
		// 	}

		// 	&.end {
		// 		margin-left: auto;
		// 	}

		// 	.header-button {
		// 		width: 100%;
		// 		height: 100%;
		// 		display: flex;
		// 		justify-content: center;
		// 		align-items: center;
		// 		cursor: pointer;

		// 		padding: 0.5rem;
		// 	}

		// 	.header-link {
		// 		transition: border 300ms ease-in-out;
		// 	}
		// }
	}

	.header-left,
	.header-right,
	.header-middle {
		display: flex;
		flex-wrap: wrap;

		@media (min-width: $breakpoint-tablet) {
			flex-wrap: nowrap;
		}
		line-height: 1.5rem;
	}
	.header-right {
		margin-left: auto;
		padding-left: 25px;
	}
	.header-middle {
		margin: 0 auto;
	}
}
