@import '../abstracts/variables.scss';
// @import '../abstracts/Animations.scss';

.image-upload {
	.file-upload {
		.form-file,
		input[type='file'] {
			display: none;
		}

		.file-upload-zone {
			padding: 10px;
			background-color: rgb(245, 245, 250);
			cursor: pointer;

			transition: background-color 250ms ease-in-out;

			&.hovered,
			&:hover {
				background-color: rgb(225, 225, 245);
			}

			.file-upload-names {
				margin-bottom: 5px;
				font-size: 0.85em;
				// max-height: 800px;
				img {
					max-height: 600px;
				}

				span {
				}

				.files-list {
					.file-item {
					}
				}
			}
		}
	}
}

.uploads-list-modal {
	h2 {
		text-align: left;
	}
	.uploads-list-filter {
		width: 100%;
		height: 38px;
		padding: 8px;
		margin-bottom: 20px;
		background-color: rgba(0, 0, 0, 0.07);
		outline: none;
		box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.33);
		border-style: none;
		// border-radius: 4px;
		// border-top-left-radius: 5px;
		// border-bottom-left-radius: 5px;

		&:hover {
			box-shadow: inset 0 -2px 0 0 rgba(22, 0, 83, 0.666);
		}
	}
}

.uploads-list {
	display: flex;
	flex-wrap: wrap;

	.uploads-list-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 200px;
		padding: 10px;
		margin: 15px;
		box-shadow: 0em 0em 0.5em 0.3em $shadow-sidebar-color;
		cursor: pointer;

		div {
			margin: 5px 0px;
		}

		.unavailable-image {
			width: 100%;
			min-height: 50px;
			padding: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgb(211, 211, 211);
		}
	}
}

.uploads-list-button {
	box-shadow: 0em 0em 0.3em 0.1em $shadow-sidebar-color;
	cursor: pointer;
	margin: 5px;
	padding: 10px;
	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	max-width: 400px;
	margin: 15px auto 15px auto;

	transition: background-color 250ms 0ms ease-in-out, fill 150ms 0ms ease-in-out, color 150ms 0ms ease-in-out;

	&:hover {
		color: #fff;
		fill: #fff;
		background-color: $color-blue;
		box-shadow: 0em 0em 0.3em 0.1em $shadow-color;
	}
}
