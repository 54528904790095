@use 'sass:math';

@import '../abstracts/mixins.scss';
@import '../abstracts/Animations.scss';
@import '../abstracts/variables.scss';

.composition-buttons-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;

	.composition-button {
		box-shadow: 0em 0em 0.3em 0.1em $shadow-sidebar-color;
		cursor: pointer;
		margin: 5px;
		padding: 10px;
		border-radius: 5px;

		display: flex;
		align-items: center;

		transition: background-color 250ms 0ms ease-in-out, fill 150ms 0ms ease-in-out, color 150ms 0ms ease-in-out;

		&:hover {
			color: #fff;
			fill: #fff;
			background-color: $color-blue;
			box-shadow: 0em 0em 0.3em 0.1em $shadow-color;
		}
	}
}

.composition-info {
	// display: flex;
	// flex-direction: column;
	// justify-content: center;
	// align-content: center;
	// align-items: center;

	display: grid;
	justify-content: center;

	&.warning {
		background-color: rgb(255, 146, 146);
	}

	.composition-price {
		display: flex;
		justify-content: stretch;
		align-items: center;

		.composition-price-label {
			margin-right: 3px;
		}
		.composition-price-input {
			width: 100%;
		}
	}
}

.cut-drawing {
	padding: 1px;

	.drawing-head {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		position: relative;
		z-index: 1;

		max-width: 1000px;
		margin: auto;

		.cut-drawing-icon {
			background-color: transparent;
			border: none;
			// @include shadow-mixin();
			padding: 10px;
			margin: 10px;
			border-radius: 4px;
			cursor: pointer;

			transition: box-shadow 150ms linear;

			svg {
				width: 50px;
				height: 50px;
			}

			&:hover {
				@include shadow-mixin();
			}
		}

		.cut-drawing-text-button {
			background-color: transparent;
			border: none;
			// @include shadow-mixin();
			padding: 10px;
			margin: 10px 5px;
			border-radius: 4px;
			cursor: pointer;

			transition: box-shadow 150ms linear;

			font-size: 1em;

			&.active {
				@include shadow-mixin();
				font-weight: 700;
			}

			&:hover {
				@include shadow-mixin();
			}
		}
	}

	.drawing-body {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		position: relative;

		z-index: 0;

		// pointer-events: none;

		.drawing-input {
			position: relative;
			// display: flex;
			// margin: auto;
			input {
				border: none;
				width: 75px;
				font-size: 18px;

				&:focus {
					outline: none;
				}
			}

			.drawing-input-label {
				--left-label-offset: 100%;
				position: absolute;
				left: 100%;
				left: var(--left-label-offset);
				top: 5px;
			}
		}

		.drawing-zone {
			width: 530px;
			height: 530px;
			padding: 90px 100px 100px 90px;
			margin: 15px;
			// border: solid #b6bbc020 3px;
			// pointer-events: all;

			.draw-area {
				width: 100%;
				height: 100%;

				position: relative;

				.object {
					&.cable {
						z-index: 10;
						transform: translate(calc(-50% + 5px), calc(-50% + 5px));
						cursor: pointer;

						&:hover {
							.ripple {
								display: block;
							}
						}

						.ripple {
							height: 50px;
							width: 50px;
							position: absolute;
							// top: -20px;
							// left: -20px;
							top: -15px;
							left: -15px;
							display: none;
							// z-index: 20;

							.bubble {
								opacity: 0;

								animation: growAndFade 2s infinite ease-out;
								background-color: hsl(210, 40%, 75%);
								border-radius: 100%;
								height: 100%;
								position: absolute;
								width: 100%;

								&:nth-child(1) {
									animation-delay: 0s;
								}
								&:nth-child(2) {
									animation-delay: 1s;
								}
								// &:nth-child(3) {
								// 	animation-delay: 2s;
								// }
								// &:nth-child(4) {
								// 	animation-delay: 3000ms;
								// }
								// &:nth-child(4) {
								// 	animation-delay: 2500ms;
								// }
							}
						}
					}

					&.profile {
						z-index: 5;
					}
					position: absolute;

					.dot {
						// width: 100%;
						// height: 100%;
						position: relative;
						width: 20px;
						height: 20px;
						border-radius: 100%;
						background-color: hsl(210, 35%, 65%);

						z-index: 10;
					}

					.dot-extra {
						position: absolute;
						top: 100%;
						left: 50%;
						transform-origin: 50% -10px;

						z-index: 7;

						user-select: none;

						// pointer-events: none;

						svg {
							height: 80px;
							width: 60px;

							// #87a6c5
							stroke: hsl(210, 35%, 65%);

							// text {
							// 	fill: rgb(33, 39, 44);
							// }
						}

						&.cable {
							svg {
								height: 60px;
								width: 40px;
							}
						}
					}

					.line {
						background-color: hsl(214, 9%, 85%);

						height: 10px;
						width: 100%;

						&.vertical {
							height: 100%;
							width: 10px;
						}
					}

					.line-wall {
						position: absolute;
						width: calc(100% - 6px);
						height: 40px;

						// $bg-full: '';
						// $size: 6px;
						// $stop: 50;
						// $i: 0;
						// @while $i <= $stop {
						// 	$bg-full: $bg-full +
						// 		'rgb(200, 200, 205) ' +
						// 		$i *
						// 		$size +
						// 		',rgb(200, 200, 205) ' +
						// 		($i * $size + 2px) +
						// 		',' +
						// 		$background-color +
						// 		' ' +
						// 		($i * $size + 2px) +
						// 		',' +
						// 		$background-color +
						// 		' ' +
						// 		($i * $size + 6px);

						// 	@if $i != $stop {
						// 		$bg-full: $bg-full + ',';
						// 	}

						// 	$i: $i + 1;
						// }

						// background-image: linear-gradient(45deg, unquote($bg-full));

						background: repeating-linear-gradient(45deg, rgb(200, 200, 205), rgb(200, 200, 205) 2px, $background-color 0px, $background-color 7px);

						bottom: 6px;
						left: 6px;

						&.internal {
							bottom: initial;
							top: 7px;
						}

						&.vertical {
							height: calc(100% - 3px);
							width: 50px;

							left: 6px;
							top: 7px;
							bottom: unset;
							transform: unset;

							&.internal {
								left: unset;
								right: 10px;
							}
						}
					}

					.line-label {
						position: absolute;
						// width: 100%;
						// height: 100%;

						left: 50%;
						bottom: 12px;
						transform: translateX(-50%);

						&.internal {
							// bottom: -12px;
							bottom: initial;
							top: 14px;
						}

						&.vertical {
							// writing-mode: vertical-lr;
							// text-orientation: mixed;

							left: 12px;
							bottom: unset;
							transform: unset;
							top: 50%;
							// transform: translateY(50%);

							&.internal {
								// bottom: -12px;
								left: unset;
								right: 20px;
								// transform: translateY(-50%) rotate(0deg);
							}
						}
					}
				}
			}
		}
	}
}
