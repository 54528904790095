@import '../abstracts/variables.scss';
@import '../abstracts/mixins.scss';

.document-header {
	position: relative;
	text-align: left;
	margin-bottom: 30px;

	@media (min-width: $breakpoint-tablet) {
		margin-bottom: 50px;
	}

	.document-header-menu {
		// min-height: 60px;
		line-height: 1.5rem;
		display: flex;
		flex-wrap: wrap;

		// border: $border-color;
		// border-width: 0 0 2px 0;
		// border-style: solid;

		.document-header-item {
			background-color: $background-color;
			color: #000;
			fill: #000;
			transition: background-color 300ms ease-in-out, color 300ms ease-in-out, fill 300ms ease-in-out, border-color 300ms ease-in-out;

			display: flex;
			justify-content: center;
			align-items: center;

			margin: 0px 7px;
			padding: 3px;

			// &.selected {
			// 	background-color: lighten($color-blue, 30%);
			// }

			.header-drop-down-container {
				background-color: $background-color;
				padding: 15px;
				border: $border-color-active 2px solid;
				color: #000;
			}

			.document-header-link {
				cursor: pointer;
				// padding: 2px;
				@include underline(black, 2px);

				&:hover {
					@include underline-hover(black);
				}

				&.active {
					@include underline-hover(black);
				}
			}

			.document-header-button {
				// padding: 5px;
				cursor: pointer;

				@include underline(black, 2px);

				&:hover {
					@include underline-hover(black);
				}

				&.active {
					@include underline-hover(black);
				}

				// &.active {
				// }
			}
		}
	}
}
