@import '../abstracts/variables.scss';
@import '../abstracts/Animations.scss';

$rt-mobile: 'only screen and (max-width : 480px)' !default;

.Toastify {
	.Toastify__toast-container {
		z-index: 1000;
		transform: translate3d(0, 0, 9999 px);
		position: fixed;
		padding: 4px;
		width: 320px;
		box-sizing: border-box;
		color: #fff;

		&.Toastify__toast-container--top-left {
			top: 1em;
			left: 1em;
		}
		&.Toastify__toast-container--top-center {
			top: 1em;
			left: 50%;
			transform: translate3d(-50%, 0, 0);
		}
		&.Toastify__toast-container--top-right {
			top: 1em;
			right: 1em;
		}
		&.Toastify__toast-container--bottom-left {
			bottom: 1em;
			left: 1em;
		}
		&.Toastify__toast-container--bottom-center {
			bottom: 1em;
			left: 50%;
			transform: translate3d(-50%, 0, 0);
		}
		&.Toastify__toast-container--botom-right {
			bottom: 1em;
			right: 1em;
		}

		@media #{$rt-mobile} {
			.Toastify__toast-container {
				width: 100vw;
				padding: 0;
				left: 0;
				margin: 0;
				&--top-left,
				&--top-center,
				&--top-right {
					top: 0;
					transform: translateX(0);
				}
				&--bottom-left,
				&--bottom-center,
				&--bottom-right {
					bottom: 0;
					transform: translateX(0);
				}
				&--rtl {
					right: 0;
					left: initial;
				}
			}
		}

		.Toastify__toast {
			position: relative;
			min-height: 64px;
			box-sizing: border-box;
			margin-bottom: 1rem;
			padding: 8px;
			border-radius: 4px;
			box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
			display: flex;
			justify-content: space-between;
			max-height: 640px;
			overflow: hidden;
			// font-family: var(--toastify-font-family);
			cursor: pointer;
			direction: ltr;

			&.Toastify__flip-enter {
				animation-name: Toastify__flipIn;
			}

			&.Toastify__flip-exit {
				animation-name: Toastify__flipOut;
			}

			&.Toastify--animate {
				// animation: flip-in-hor-bottom 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
				animation-fill-mode: both;
				animation-duration: 0.7s;
			}

			&.Toastify--animate-icon {
				animation-fill-mode: both;
				animation-duration: 0.3s;
			}

			&.Toastify__toast-theme--light {
				background: #fff;
				color: #000;
			}
			&.Toastify__toast-theme--colored.Toastify__toast--default {
				background: #fff;
				fill: #000;
				color: #000;

				.Toastify__toast-body {
					.Toastify__toast-icon {
						svg {
							fill: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
						}
					}
				}
			}

			&.Toastify__toast-theme--colored.Toastify__toast--info {
				background: #fff;
				color: #000;

				.Toastify__toast-body {
					.Toastify__toast-icon {
						svg {
							fill: $toastify-color-info;
						}
					}
				}
			}
			&.Toastify__toast-theme--colored.Toastify__toast--success {
				background: #fff;
				color: rgb(31, 31, 31);

				.Toastify__toast-body {
					.Toastify__toast-icon {
						svg {
							fill: $toastify-color-success;
						}
					}
				}
			}
			&.Toastify__toast-theme--colored.Toastify__toast--warning {
				background: #fff;
				color: #000;

				.Toastify__toast-body {
					.Toastify__toast-icon {
						svg {
							fill: $toastify-color-warning;
						}
					}
				}
			}
			&.Toastify__toast-theme--colored.Toastify__toast--error {
				background: #fff;
				color: #fff;

				.Toastify__toast-body {
					.Toastify__toast-icon {
						svg {
							fill: $toastify-color-error;
						}
					}
				}
			}

			.Toastify__toast-body {
				margin: auto 0;
				flex: 1 1 auto;
				padding: 6px;
				display: flex;
				align-items: center;
				color: #555555;
			}

			.Toastify__toast-icon {
				margin-inline-end: 10px;
				width: 20px;
				flex-shrink: 0;
				display: flex;

				.Toastify__spinner {
					width: 20px;
					height: 20px;
					box-sizing: border-box;
					border: 2px solid;
					border-radius: 100%;
					border-color: #e0e0e0;
					border-right-color: #616161;
					animation: icon-spin 0.65s linear infinite;
				}
			}

			.Toastify__close-button {
				background: transparent;
				outline: none;
				border: none;
				padding: 0;
				cursor: pointer;
				transition: 0.3s ease;
				align-self: flex-start;

				color: #000;
				opacity: 0.5;

				// &--light {
				// 	color: #000;
				// 	opacity: 0.3;
				// }

				& > svg {
					fill: currentColor;
					height: 16px;
					width: 14px;
				}

				&:hover,
				&:focus {
					opacity: 1;
				}
			}

			.Toastify__progress-bar {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 5px;
				z-index: 1000;
				opacity: 0.7;
				transform-origin: left;

				&.Toastify__progress-bar--animated {
					animation: Toastify__trackProgress linear 1 forwards;
				}

				&.Toastify__progress-bar--controlled {
					transition: transform 0.2s;
				}

				&.Toastify__progress-bar--rtl {
					right: 0;
					left: initial;
					transform-origin: right;
				}

				&.Toastify__progress-bar-theme--light {
					background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
				}
				&.Toastify__progress-bar--default {
					background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
				}
				&.Toastify__progress-bar--info {
					background: $toastify-color-info;
				}
				&.Toastify__progress-bar--success {
					background: $toastify-color-success;
				}
				&.Toastify__progress-bar--warning {
					background: $toastify-color-warning;
				}
				&.Toastify__progress-bar--error {
					background: $toastify-color-error;
				}
			}
		}
	}
}
