@import '../abstracts/variables.scss';

.status-panel {
	background-color: $background-color;
	padding: 15px;
	border: $border-color-active 2px solid;
	color: #000;

	.status-option {
		padding: 5px;
		margin: 5px;
	}
}
