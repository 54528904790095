@use 'sass:math';

$boxSize: 40px;
$boxMargin: math.div($boxSize, 3);
$boxColor1: #ce9ffc;
$boxColor2: #7367f0;
$boxBorderRadius: 50%;

$delay: 0.1s;

@mixin centerFlex {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.loading-boxes {
	margin: auto;
	width: 200px;
	@include centerFlex;
	flex-wrap: wrap;
	.loading-box {
		margin: $boxMargin;
		width: $boxSize;
		height: $boxSize;
		border-radius: $boxBorderRadius;
		opacity: 0;
		background: linear-gradient(130deg, $boxColor1 0%, $boxColor2 100%);
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

		animation: pulse 1s linear infinite alternate;
		&:nth-child(1) {
			animation-delay: 0 * $delay;
		}
		&:nth-child(2) {
			animation-delay: 0 * $delay;
		}
		&:nth-child(3) {
			animation-delay: 1 * $delay;
		}
		&:nth-child(4) {
			animation-delay: 2 * $delay;
		}
		&:nth-child(5) {
			animation-delay: 3 * $delay;
		}
		&:nth-child(6) {
			animation-delay: 4 * $delay;
		}
		&:nth-child(7) {
			animation-delay: 5 * $delay;
		}
		&:nth-child(8) {
			animation-delay: 6 * $delay;
		}
		&:nth-child(9) {
			animation-delay: 7 * $delay;
		}
	}
}

@keyframes pulse {
	from,
	40% {
		opacity: 0;
	}
	50% {
		opacity: 0.5;
	}
	80%,
	to {
		opacity: 1;
	}
}

@media screen and (max-width: 300px) {
	.box {
		width: 30px !important;
		height: 30px !important;
	}
}
