@import '../abstracts/variables.scss';

.footer {
	position: sticky;
	bottom: 0;
	left: 0%;
	background-color: $background-color;
	z-index: 600;
	margin-top: 100px;

	height: 60px;
	width: 100%;

	border: $border-color;
	border-width: 2px 0 0 0;
	border-style: solid;

	box-shadow: 0px -8px 8px -4px $shadow-color;

	display: flex;
	justify-content: center;
	align-items: center;
}
