@import '../abstracts/variables.scss';
@import '../abstracts/Animations.scss';

@import '../components/Shipping.scss';
@import '../components/Zone.scss';

.document {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: auto;
	align-self: auto;

	width: 100%;
	margin: 5px auto;
	padding: 5px 5px 5px 20px;

	@media (min-width: $breakpoint-tablet) {
		padding: 5px 25px;
	}

	.search-tags-default-list {
		display: flex;
		flex-wrap: wrap;
		margin: 5px 0px 5px -5px;
		font-size: 0.9em;

		& > div {
			margin: 3px 5px;
			padding: 3px;
			border: solid 1px black;
			cursor: pointer;
			user-select: none;

			&.active {
				background-color: darken($color-blue, 5%);
				color: #fff;
				fill: #fff;
			}
		}
	}

	.search-tags-list {
		display: flex;
		flex-wrap: wrap;
		margin: 7px 0px 7px -7px;

		& > div {
			margin: 3px 7px;
			padding: 3px;
			border-bottom: solid 1px black;

			&.active {
				background-color: $color-blue;
				color: #fff;
				fill: #fff;
			}
		}
	}

	.document-input-with-label {
		flex-grow: 1;
		margin-right: 25px;
		display: flex;
		// justify-content: center;
		align-items: center;
		flex-wrap: nowrap;

		.document-input__label-zone {
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 600;

			&.clickable {
				cursor: pointer;
			}
			.input-label {
				margin-right: 5px;
				white-space: nowrap;
			}

			.input-icon {
				margin-right: 10px;
			}
		}
	}

	.element-container {
		position: relative;
		margin-top: 0.75rem;
		padding: 0.5rem 0.75rem 0.5rem 0rem;
		outline: none;

		@media (min-width: $breakpoint-tablet) {
			position: relative;
			margin-top: 1rem;
			padding: 0.75rem 1rem 0.75rem 0rem;
		}

		.element {
			position: relative;
			padding-left: 0.3rem;
			border-radius: 0.5rem;
			width: 100%;

			&:hover {
				background: rbga(0, 0, 0, 0.5);
			}
		}
		.element-action {
			position: absolute;
			// opacity: 0;

			animation-name: show-opacity;

			@media (min-width: $breakpoint-laptop) {
				animation-name: hide-opacity;
			}
			animation-duration: 500ms;
			animation-fill-mode: forwards;
			animation-timing-function: ease-in-out;

			&.element-drag {
				z-index: 10;
				left: 0%;
				top: 50%;

				transform: translate3d(-100%, -50%, 0);
			}
			&.element-remove {
				right: 0%;
				top: 0%;
				transform: translate3d(40%, -40%, 0);

				&.element-remove-mid {
					top: 25%;
					transform: translate3d(40%, -40%, 0);
				}
			}
			&.element-refresh {
				right: 0%;
				top: 0%;
				transform: translate3d(calc(-60% - 10px), -40%, 0);

				&.element-refresh-mid {
					top: 25%;
					transform: translate3d(calc(-60% - 10px), -40%, 0);
				}
			}
			&.element-copy {
				right: 0%;
				bottom: 0%;
				transform: translate3d(40%, 40%, 0);
			}
		}

		&:hover {
			.element-action {
				animation-name: show-opacity;
			}
		}
	}
}
