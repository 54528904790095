@import '../abstracts/variables.scss';

.Tooltip {
	background: $tooltip-color;
	color: $background-color;
	pointer-events: none;
	border-radius: 6px;
	padding: 6px 8px;
	font-size: 14px;
	z-index: 499;
}
