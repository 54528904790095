// Responsive
$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 1080px;
$breakpoint-desktop: 1400px;
$breakpoint-xl: 1680px;

// Colors
$shadow-color: rgba(0, 128, 255, 0.2);
$shadow-sidebar-color: rgba(90, 90, 90, 0.2);
$shadow-option-color: rgba(0, 166, 207, 0.3);
$background-color: #fff;
$border-color: rgb(228, 240, 248);
$border-color-active: rgb(148, 173, 211);
$color-blue: rgb(55, 141, 253);
$color-disable: rgb(125, 129, 136);
$tooltip-color: rgba(4, 15, 26, 0.801);

$toastify-color-light: #fff;
$toastify-color-dark: #121212;
$toastify-color-info: #3498db;
$toastify-color-success: #07bc0c;
$toastify-color-warning: #f1870f;
$toastify-color-error: #e74c3c;
