.filter-search {
	margin: 10px;
	.filter-search-general {
		display: flex;
		justify-content: center;

		.filter-search-input {
			padding: 8px;
			background-color: rgba(0, 0, 0, 0.07);
			outline: none;
			box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.33);
			border-style: none;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}
		.filter-search-input-icon {
			padding: 4px;
			background-color: rgba(0, 0, 0, 0.66);

			border-style: solid;
			border-width: 1px;
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
			fill: #fff;
		}
	}
}
