@import '../abstracts/Animations.scss';

$modal-transition-time: 300ms;

.modal-provider {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	// z-index: -1;
	// transition: z-index 0ms 500ms linear;

	z-index: 750;
	visibility: hidden;
	transition: visibility 0ms $modal-transition-time ease-in-out;

	&.show {
		// transition: z-index 0ms 0ms linear;
		// z-index: 9999;

		visibility: visible;
		transition: visibility 0ms 0ms ease-in-out;
	}

	.modal-container {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100vw;
		height: 100vh;

		.modal-content {
			display: flex;
			position: relative;
			max-width: 90vw;
			max-height: 90vh;
			// overflow: auto;
			z-index: 20;
			padding: 1rem;
			background-color: #fff;
			border-radius: 0.5rem;
			opacity: 0;

			animation-name: hide-opacity;
			animation-duration: $modal-transition-time;
			animation-fill-mode: forwards;

			visibility: hidden;
			transition: visibility 0ms $modal-transition-time ease-in-out;

			&.show {
				// opacity: 1;
				visibility: visible;
				transition: visibility 0ms 0ms ease-in-out;

				animation-name: show-opacity;
				animation-duration: $modal-transition-time;
				animation-fill-mode: forwards;
			}

			&.invisible {
				background-color: transparent;
				padding: 0;
				border-radius: 0;
				display: block;
			}

			.modal-close {
				position: absolute;
				top: 0;
				right: 0;

				z-index: 50;

				cursor: pointer;
				width: 3rem;
				height: 3rem;
				padding: 0.2rem;
				border-radius: 100%;
				transform: translate(110%, -10%);

				// background: #fff;
				background: rgb(52, 125, 235);
				svg {
					border-radius: 100%;
					fill: #fff;
					// fill: rgb(52, 125, 235);
				}
			}
		}
		.modal-background {
			position: fixed;
			top: 0;
			left: 0;
			height: 100vh;
			width: 100vw;
			background: rgba(0, 0, 0, 0.9);

			z-index: 1;
			opacity: 0;

			animation-name: hide-opacity;
			animation-duration: $modal-transition-time;
			animation-fill-mode: forwards;

			visibility: hidden;
			transition: visibility 0ms $modal-transition-time ease-in-out;

			&.show {
				// opacity: 1;
				visibility: visible;
				transition: visibility 0ms 0ms ease-in-out;

				animation-name: show-opacity;
				animation-duration: $modal-transition-time;
				animation-fill-mode: forwards;
			}
		}
	}
}
