@import '../abstracts/variables.scss';

.list-list {
	margin-top: 20px;
	// width: 80vw;
	width: 100%;
	padding: 20px;
	// max-height: 80vh;
	overflow: auto;

	.list-actions {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 10px;

		.action-button {
			border-radius: 5px;
			padding: 10px;
			cursor: pointer;
			box-shadow: inset 0 0 0 2px $shadow-color;

			transition: background-color 250ms 0ms ease-in-out, fill 150ms 0ms ease-in-out, color 150ms 0ms ease-in-out;

			&:hover {
				background-color: $color-blue;
				color: #fff;
				fill: #fff;
			}
		}
	}

	.list-row {
		width: 100%;
		display: flex;
		// flex-direction: column;
		flex-wrap: wrap;
		border-style: solid;

		@media (min-width: $breakpoint-tablet) {
			// flex-direction: row;
			flex-wrap: nowrap;
			border-style: none;
		}

		&.row-header {
			margin-top: 10px;
			font-weight: 600;
			font-size: 120%;
			align-items: flex-end;
		}

		.sorting-icon {
			margin: 0px 7px;
		}

		.list-column {
			text-align: left;
			padding: 7px;
			border-width: 0;
			border-bottom: 1px;
			// border-right: 1px;
			// border-left: 1px;
			border-style: inset;
			word-break: break-word;
			min-width: 150px;

			@media (min-width: $breakpoint-tablet) {
				border-style: solid;
			}

			&.item-clickable {
				cursor: pointer;
				transition: background-color 250ms 0ms ease-in-out, fill 150ms 0ms ease-in-out, color 150ms 0ms ease-in-out;

				&:hover {
					background-color: lighten($color-blue, 20%);
				}
			}

			&.item-small {
				width: 50%;
				@media (min-width: $breakpoint-tablet) {
					// width: 15%;
					flex-grow: 1;
					flex-shrink: 2;
				}
			}
			&.item-medium {
				width: 50%;
				@media (min-width: $breakpoint-tablet) {
					// width: 30%;
					flex-grow: 2;
					flex-shrink: 1;
				}
			}

			.list-column-label {
				text-align: left;
				font-weight: 600;
				padding-right: 0.5rem;
				margin-bottom: 5px;
				width: 100%;

				word-wrap: break-word;
				@media (min-width: $breakpoint-tablet) {
					display: none;
				}
			}
		}
	}
}
