@mixin underline($color, $height) {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: $height;
		bottom: -3px - $height;
		left: 0;
		background-color: $color;

		transform: scaleX(0);
		transform-origin: bottom right;
		transition: transform 0.3s ease-out, background-color 0.3s ease-out;
	}
}

@mixin underline-hover($color) {
	&::after {
		background-color: $color;
		transform: scaleX(1);
		transform-origin: bottom left;
	}
}

@mixin shadow-mixin() {
	box-shadow: 0 3px 10px rgba(87, 87, 87, 0.2);
}
