$background-color: rgb(236, 236, 236);

.pagination-list {
	list-style: none;
	display: flex;

	.pagination-item {
		padding: 6px 9px;
		margin: 0px 4px;
		// height: 1.25rem;
		border-radius: 4px;

		&.enabled {
			background-color: $background-color;
			// background-color: darken($background-color, 10%);
			cursor: pointer;
			user-select: none;
			border-style: none;
			box-shadow: 0 0 0 1px #adc2ff;

			&:hover:not(&.active) {
				background-color: darken($background-color, 10%);
				// background-color: $background-color;
			}
		}

		&.active {
			background-color: transparent;
		}
	}
}
