@import '../abstracts/variables.scss';

$clouds: #ecf0f1;
$turquoise: #1abc9c;
$greensea: #16a085;
$night: #34495e;
$midnight: #2c3e50;
$transition: transform 350ms ease, background-color 350ms ease;

.toggle-switch {
	// display: flex;
	// justify-content: center;
	// align-items: center;
	width: 5.5em;
	padding: 0.5em;
	margin: 0 auto;

	/* Hide the input */
	input[type='checkbox'] {
		position: absolute;
		visibility: hidden;
		opacity: 0;
		z-index: -1;
	}
	.check-trail {
		display: flex;
		align-items: center;
		width: 4em;
		height: 1.5em;
		background: $color-disable;
		border-radius: 0.75em;
		transition: $transition;
		cursor: pointer;
	}
	.check-handler {
		display: flex;
		// margin: 0 0.5em;
		justify-content: center;
		align-items: center;
		width: 2em;
		height: 2em;
		background: darken($color-disable, 15%);
		border-radius: 50%;
		transition: $transition;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
		&:before {
			content: '\00D7';
			color: white;
			font-size: 1em;
			font-weight: bold;
		}
	}
	input[type='checkbox']:checked + .check-trail {
		background: darken($color-blue, 15%);
		.check-handler {
			// margin-left: 100%;
			transform: translate3d(2em, 0, 0);
			background: lighten($color-blue, 10%);
			&::before {
				content: '\2714';
			}
		}
	}
}
